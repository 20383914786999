.containerr {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    max-width: 1500px;
    margin: auto;
    background-color: #F2F0F4;
    padding: 100px;
    place-items: center;
  }
  
  .toolbar {
    display: flex;
    justify-content: space-between;
  }
  
  .button {
    padding: 20px 10px;
    margin: 10px auto;
    width: 30%;
    border: black;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .uploadButton {
    background-color: white;
    color: black;
    font-weight: bold;
  }
  
  .evaluateButton {
    background-color: white;
    color: black;
    font-weight: bold;
  }
  
  .candidate-list {
    max-height: 500px;
    overflow-y: auto;
  }

  .candidate-item {
    display: flex;
    height: 200px;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
  }
  
  .candidate-name, .candidate-description, .candidate-result, .candidate-navigate {
    margin: 10px 10px;
  }
  
  .candidate-name {
    font-weight: bold;
    flex-basis: 15%;
    min-width: 100px;
  }

  .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #2AB514;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: white;
  }
  
  .text {
    font-weight: bold;
  }
  
  
  .candidate-description, .candidate-additional-text {
    width: 300px;
    height: 160px;
    background: #f2f2f2;
    border-radius: 5px;
    padding: 10px;
    overflow: scroll;
  }
  
  .candidate-navigate {
    flex-basis: 10%;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  .progress-bar-container {
    width: 80%;
    justify-content: center;
    background-color: #e0e0e0;
    border-radius: 15px;
    margin: 20px auto;
    height: 20px;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #4CAF50;
    width: 0;
    border-radius: 15px;
    transition: width 0.4s ease;
  }  

  .notify-button-container {
    display: grid;
    place-items: center;
  }

  .notify-button {
    width: 40%;
    padding: 15px 0;
    background-color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-top: 50px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  /* Container for the checkbox */
.checkbox-container {
    display: flex;
    align-items: center;

  }
  /* Style for the custom checkbox */
.checkbox-input {
    display: none;
  }
  
  /* Style for the custom checkbox label */
  .checkbox-label {
    position: relative;
    padding: 10px 20px; /* Adjust as needed for spacing */
    margin-left: 10px;
    cursor: pointer;
  }
  
  /* Custom checkbox indicator */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff; /* Background color of the checkbox */
    border: 2px solid #333; /* Border color of the checkbox */
    border-radius: 4px; /* Rounded corners */
    transition: background-color 0.3s, border-color 0.3s; /* Add smooth transition */
  }
  
  /* Style for the custom checkmark icon (the "X") */
  .checkmark:after {
    content: "\f00c"; /* Unicode character for the Font Awesome check icon */
    font-family: FontAwesome; /* Specify the Font Awesome font family */
    position: absolute;
    left: 2px; /* Adjust positioning as needed */
    top: 1px; /* Adjust positioning as needed */
    color: #2AB514; /* Color of the checkmark icon */
    font-size: 16px; /* Adjust font size as needed */
    display: none;
  }
  
  /* Show the custom checkmark when checked */
  .checkbox-input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the custom checkbox when checked */
  .checkbox-input:checked ~ .checkmark {
    border-color: #333; /* Border color when checked */
  }
  .pbar {
    display: flex;  
    align-items: center
  }
  .button1 {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
  }
  .clicked {
    border-bottom: 3px solid #86EFAC;
  }
  .clicked2 {
    border-bottom: 3px solid #6366F1;
  }

  .equal-width-button {
    width: 200px; /* Adjust the width as per your requirement */
  }


  /* Spinner.css */

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
 }

.spinner-icon {
  width: 13px; /* Adjust size as needed */
  height: 13px; /* Adjust size as needed */
  border: 2px solid #ccc; /* Adjust border color as needed */
  border-top-color: #333; /* Adjust top border color as needed */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
