

.container{
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 200px;
    background: #fff;
    padding-bottom: 30 px;
    width: 600px;
}

.login-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
}

.text{
    color: #3c009d;
    font-size: 48px;
    font-weight: 700;
}

.underline{
    width: 61px;
    height: 6px;
    background: #3c009d;
    border-radius: 9px;
}

.inputs{
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.input{
    display: flex;
    align-items: center;
    margin: auto;
    width: 480px;
    height: 80px;
    background: #eaeaea;
    border-radius: 6px;
}

.input img{
    margin: 0px 30px;
}

.input input{
    height: 50px;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 19px;
}

.forgot-password{
     padding-left: 62px;
     margin-top: 27px;
     color: #797979;
     font-size: 18px;
}

.forgot-password span{
    color: #4c00b4;
    cursor: pointer;
}

.submit-container{
    display: flex;
    gap: 30px;
    margin: 60px auto;
}

.submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 59px;
    color: #fff;
    background: #3c009d;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700px;
    cursor: pointer;
    }
    .gray{
        background: #eaeaea;
        color: #676767
    }

    .info-icon {
    position: relative;
}

.tooltip {
    visibility: hidden;
    width: 300px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}
 

.info-icon:hover .tooltip {
    visibility: visible;
    opacity: 1;
}
